import { MutableRefObject, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { dashboardButtonStyles } from "./Components/Buttons/dashboardButtonStyles";
import { DisableableNavLink } from "./Components/Navigation/DisableableNavLink";
import { HeaderClickable, HeaderItem, HeaderLabel, UserMenu } from "./Header/HeaderStyles";
import { useCurrentDashboard } from "./Hooks/Dashboard/currentDashboard";
import { DashboardHeaderIcon, DashboardMenuIcon } from "./Hooks/Dashboard/dashboardIcons";
import {
  cloudManager,
  globalSettings,
  infrastructureDashboard,
  mobileNetworksDashboard,
  serviceOrchestration,
  softwarePublisher,
  tenantSettings
} from "./Hooks/Dashboard/dashboards";
import { useDefaultOrg } from "./Hooks/defaultOrg";
import { useCloudManagerDashboard } from "./Hooks/Permissions/cloudManagerDashboard";
import { useCanAccessGlobalSettingsDashboard } from "./Hooks/Permissions/globalSettingsDashboard";
import { useInfrastructureDashboard } from "./Hooks/Permissions/infrastructureDashboard";
import { useMobileNetworksDashboard } from "./Hooks/Permissions/mobileNetworksDashboard";
import { useServiceOrchestration } from "./Hooks/Permissions/serviceOrchestration";
import { useSoftwarePublisher } from "./Hooks/Permissions/softwarePublisher";
import { useCanAccessTenantSettingsDashboard } from "./Hooks/Permissions/tenantSettingsDashboard";
import { useToggle } from "./Hooks/toggle";

const DashboardDisplayName = styled(HeaderLabel)`
  white-space: nowrap;
`;
const DashboardsMenuWrapper = styled(UserMenu)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 4px;
  justify-items: center;
`;

const DashboardIconLink = styled(DisableableNavLink)<{ $active: boolean }>`
  ${dashboardButtonStyles};
  width: 80px;
  text-decoration: none;
`;

export function DashboardsMenu() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const {
    state: isOpenDashboards,
    toggle: toggleMenuDashboards,
    off: closeMenuDashboards
  } = useToggle();

  const dashboardsMenu = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(dashboardsMenu, closeMenuDashboards);

  const {
    isServiceOrchestration,
    isSoftwarePublisher,
    isTenantSettings,
    isInfrastructure,
    isMobileNetworks,
    isCloudManager,
    isGlobalSettings,
    dashboard
  } = useCurrentDashboard();

  const { allowed: allowServiceOrchestration } = useServiceOrchestration();
  const { allowed: allowPublisher } = useSoftwarePublisher();
  const { allowed: accessInfrastructure } = useInfrastructureDashboard();
  const { allowed: accessMobileNetworks } = useMobileNetworksDashboard();
  const { allowed: accessCloudManager } = useCloudManagerDashboard();
  const { allowed: accessTenantSettings } = useCanAccessTenantSettingsDashboard();
  const { allowed: accessGlobalSettings } = useCanAccessGlobalSettingsDashboard();

  const org = orgCtx || defaultOrg;

  return (
    <HeaderItem ref={dashboardsMenu}>
      <HeaderClickable onClick={toggleMenuDashboards} title="dashboards menu">
        <DashboardHeaderIcon dashboard={dashboard.name} />
        <DashboardDisplayName>{dashboard.displayName}</DashboardDisplayName>
        {isOpenDashboards ? (
          <UpArrow height={14} width={14} />
        ) : (
          <DownArrow height={14} width={14} />
        )}
      </HeaderClickable>
      {isOpenDashboards && (
        <DashboardsMenuWrapper onClick={closeMenuDashboards}>
          {allowServiceOrchestration && (
            <DashboardIconLink
              to={serviceOrchestration.route(org)}
              $active={isServiceOrchestration}
            >
              <DashboardMenuIcon dashboard={serviceOrchestration.name} />
              <span>{serviceOrchestration.displayName}</span>
            </DashboardIconLink>
          )}
          {accessInfrastructure && (
            <DashboardIconLink to={infrastructureDashboard.route(org)} $active={isInfrastructure}>
              <DashboardMenuIcon dashboard={infrastructureDashboard.name} />
              <span>{infrastructureDashboard.displayName}</span>
            </DashboardIconLink>
          )}
          {accessMobileNetworks && (
            <DashboardIconLink to={mobileNetworksDashboard.route(org)} $active={isMobileNetworks}>
              <DashboardMenuIcon dashboard={mobileNetworksDashboard.name} />
              <span>{mobileNetworksDashboard.displayName}</span>
            </DashboardIconLink>
          )}
          {allowPublisher && (
            <DashboardIconLink to={softwarePublisher.route(org)} $active={isSoftwarePublisher}>
              <DashboardMenuIcon dashboard={softwarePublisher.name} />
              <span>{softwarePublisher.displayName}</span>
            </DashboardIconLink>
          )}
          {accessCloudManager && (
            <DashboardIconLink to={cloudManager.route(org)} $active={isCloudManager}>
              <DashboardMenuIcon dashboard={cloudManager.name} />
              <span>{cloudManager.displayName}</span>
            </DashboardIconLink>
          )}
          {accessTenantSettings && (
            <DashboardIconLink to={tenantSettings.route(org)} $active={isTenantSettings}>
              <DashboardMenuIcon dashboard={tenantSettings.name} />
              <span>{tenantSettings.displayName}</span>
            </DashboardIconLink>
          )}
          {accessGlobalSettings && (
            <DashboardIconLink to={globalSettings.route(org)} $active={isGlobalSettings}>
              <DashboardMenuIcon dashboard={globalSettings.name} />
              <span>{globalSettings.displayName}</span>
            </DashboardIconLink>
          )}
        </DashboardsMenuWrapper>
      )}
    </HeaderItem>
  );
}
