import { softwarePublisher } from "@src/Hooks/Dashboard/dashboards";

import { routesRedirect } from "./complexRoutesRedirect";

export function softwarePublisherRedirect(path: string, org: string) {
  const oldRoute = `/app/${org}/services/publisher`;
  const rootRoute = softwarePublisher.route(org);

  if (path === oldRoute) {
    return routesRedirect(path, rootRoute);
  }
  if (
    path.includes(oldRoute + "/templates") ||
    path.includes(oldRoute + "/editor") ||
    path.includes(oldRoute + "/publish")
  ) {
    return routesRedirect(path, rootRoute);
  }

  return rootRoute;
}
