import AppDesignIcon from "@img/app-design.svg";
import FederationIcon from "@img/federation.svg";
import TenantsIcon from "@img/groups.svg";
import MECSitesIcon from "@img/mec-sites.svg";
import ObserveIcon from "@img/observe.svg";
import OperateIcon from "@img/operate.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { cloudManager } from "@src/Hooks/Dashboard/dashboards";
import { useCanViewFederation } from "@src/Hooks/Permissions/federation";
import { useCanCreateMECChain } from "@src/Hooks/Permissions/mecChain";
import { useCanReadAllOrgs } from "@src/Hooks/Permissions/org";

export function MECNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = cloudManager.route(orgCtx);

  const { allowed: canCreateMECChain } = useCanCreateMECChain(orgCtx);
  const { allowed: canAccessTenants } = useCanReadAllOrgs();
  const { allowed: canViewFederation } = useCanViewFederation(orgCtx);

  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "/deployed-apps"} label="Applications" icon={OperateIcon} />
      <NavItem
        linkTo={rootPath + "/app-designer"}
        label="App Designer"
        icon={AppDesignIcon}
        disabled={!canCreateMECChain}
      />
      <NavItem linkTo={rootPath + "/mec-sites"} label="MEC Sites" icon={MECSitesIcon} />
      {canAccessTenants && (
        <NavItem linkTo={rootPath + "/tenants"} label="Tenants" icon={TenantsIcon} />
      )}
      <NavItem linkTo={rootPath + "/observe"} label="Observe" icon={ObserveIcon} />
      {canViewFederation && (
        <NavItem
          disabled={!canViewFederation}
          linkTo={rootPath + "/federation"}
          label="Federations"
          icon={FederationIcon}
        />
      )}
    </NavIcons>
  );
}
