import { FormikProps } from "formik";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useMutation } from "@apollo/client";
import { ToastLongError, ToastNotification } from "@src/Components/ToastNotification";
import { requestErrorKey } from "@src/Error/ErrorPage";
import {
  BlockChart,
  FetchBlockInformationQuery,
  FetchBlockInformationQueryVariables,
  PopulateMarketplaceQuery,
  PopulateMarketplaceQueryVariables,
  PublishBlockChartInput,
  PublishBlockChartMutation,
  PublishBlockChartMutationVariables
} from "@src/Generated/graphql";
import FetchBlockInformation from "@src/SharedViews/ServiceDesigner/FetchBlockInformation.graphql";
import PopulateMarketplace from "@src/SharedViews/ServiceDesigner/Marketplace/PopulateMarketplace.graphql";

import { compareSemanticVersions } from "./compareSemVer";
import { emptyTemplateForm } from "./PublisherPage";
import PublishBlockChart from "./PushBlock.graphql";
import { BlockPublisherForm, serialiseBlock } from "./serialise";

export type PublishState = {
  submitted: boolean;
  success: boolean;
  message: string | null;
};

export function usePublishBlock() {
  const [publishBlockChart] = useMutation<
    PublishBlockChartMutation,
    PublishBlockChartMutationVariables
  >(PublishBlockChart);

  const [publishState, setPublishState] = useState<PublishState>({
    submitted: false,
    success: false,
    message: null
  });

  const handleError = useCallback((e: Error, setSubmitting: (isSubmitting: boolean) => void) => {
    const errorTitle = "failed to publish block";

    console.error(errorTitle, e);

    const showError = String(e).length <= 400;

    if (!showError) {
      window.localStorage.setItem(
        requestErrorKey,
        JSON.stringify({
          message: e.message,
          stack: e?.stack
        })
      );
    }

    toast.error(
      <ToastNotification title={errorTitle} msg={showError ? String(e) : null}>
        {!showError && <ToastLongError />}
      </ToastNotification>,
      {
        style: {
          maxWidth: "40vw",
          overflowWrap: "anywhere"
        }
      }
    );

    setPublishState(prev => ({ ...prev, message: String(e), submitted: true }));
    setSubmitting(false);
  }, []);

  const publish = useCallback(
    async (
      form: BlockPublisherForm,
      { resetForm, setSubmitting }: FormikProps<BlockPublisherForm>
    ) => {
      let serialised: PublishBlockChartInput;
      try {
        serialised = serialiseBlock(form);
      } catch (e) {
        handleError(e, setSubmitting);
        return;
      }

      try {
        const pushResult = await publishBlockChart({
          variables: {
            input: serialised
          },
          update: (cache, { data: { publishBlockChart } }) => {
            cache.updateQuery<PopulateMarketplaceQuery, PopulateMarketplaceQueryVariables>(
              {
                query: PopulateMarketplace,
                variables: { category: null, vendors: [] }
              },
              data => updatePopulateMarketplaceQuery(data, publishBlockChart)
            );
            cache.updateQuery<FetchBlockInformationQuery, FetchBlockInformationQueryVariables>(
              {
                query: FetchBlockInformation,
                variables: { name: publishBlockChart?.name }
              },
              data => {
                if (!data) return null;
                const updatedVersions = (data?.blockChart?.availableVersions || [])
                  .concat(publishBlockChart.version)
                  .sort(compareSemanticVersions);
                return {
                  ...data,
                  blockChart: {
                    ...data?.blockChart,
                    version: updatedVersions[0],
                    availableVersions: updatedVersions
                  }
                };
              }
            );
          }
        });
        // We must get a name to show the user
        const { displayName } = pushResult.data.publishBlockChart;
        const readableDate = new Date().toLocaleString();
        const successMessage = `successfully published ${displayName} at ${readableDate}`;
        toast.success(<ToastNotification title={successMessage} />);
        setPublishState(prev => ({
          ...prev,
          success: true,
          submitted: true,
          message: "Block has been published"
        }));
      } catch (e) {
        handleError(e, setSubmitting);
        return;
      }

      resetForm({
        values: {
          ...emptyTemplateForm
        }
      });

      setSubmitting(false);
    },
    [handleError, publishBlockChart]
  );

  return { publish, publishState, setPublishState };
}

function updatePopulateMarketplaceQuery(
  data: PopulateMarketplaceQuery,
  publishBlockChart: BlockChart
) {
  if (!data) return null;
  const blockChart = (data?.blockCharts || []).find(b => b.name === publishBlockChart.name);
  if (!blockChart) {
    return {
      ...data,
      blockCharts: [...(data?.blockCharts || []), { ...publishBlockChart }]
    };
  }
  const updatedBlockCharts = (data?.blockCharts || []).map(b => {
    if (b.name !== publishBlockChart.name) return b;
    const updatedVersions = b.availableVersions
      .concat(publishBlockChart.version)
      .sort(compareSemanticVersions);

    return {
      ...b,
      version: updatedVersions[0],
      availableVersions: updatedVersions
    };
  });
  return {
    ...data,
    blockCharts: updatedBlockCharts
  };
}
