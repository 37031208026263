import { serviceOrchestration } from "@src/Hooks/Dashboard/dashboards";

import { routesRedirect } from "./complexRoutesRedirect";

export function serviceOrchestrationRedirect(path: string, org: string) {
  const oldRoute = `/app/${org}/services`;
  const rootRoute = serviceOrchestration.route(org);

  if (oldRoute === path) {
    return routesRedirect(path, rootRoute);
  }

  if (path.includes("/overview") || path.includes("/service-designer")) {
    return routesRedirect(path, rootRoute);
  }

  return rootRoute;
}
