import { Tab } from "@src/Components/Navigation/Tab";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useCanCreateBlockChart } from "@src/Hooks/Permissions/blockchart";

export function TabBar() {
  const orgCtx = useOrgCtx();
  const { allowed } = useCanCreateBlockChart(orgCtx);

  return (
    <nav>
      <Tab to="templates">Templates</Tab>
      <Tab to="editor">Editor</Tab>
      <Tab disabled={!allowed} to="publish">
        Publish
      </Tab>
    </nav>
  );
}
