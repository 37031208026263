import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NoNetwork from "@img/no-network.svg";
import {
  NewResourceButton,
  unavailableIconStyles,
  UnavailablePageWrapper
} from "@src/Components/unavailableResourceStyles";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { mobileNetworksDashboard } from "@src/Hooks/Dashboard/dashboards";

const NoNetworkIcon = styled(NoNetwork)`
  ${unavailableIconStyles}
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export function NoNetworksPage() {
  const orgCtx = useOrgCtx();
  const navigate = useNavigate();

  const goToAddNetwork = useCallback(() => {
    navigate(`${mobileNetworksDashboard.route(orgCtx)}/network/add`);
  }, [navigate, orgCtx]);

  return (
    <UnavailablePageWrapper>
      <NoNetworkIcon />
      <Message>No networks available at the moment.</Message>
      <NewResourceButton onClick={goToAddNetwork}>Add New Network</NewResourceButton>
    </UnavailablePageWrapper>
  );
}
