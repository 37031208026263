import { Form, FormikProps } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { ErrorLine, Fieldset, SuccessLine, WarningLine } from "@src/Components/Input/InputGroup";
import { tabContentPadding } from "@src/Components/StyleConstants/padding";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useCanCreateBlockChart } from "@src/Hooks/Permissions/blockchart";

import { PublishState } from "../publishBlock";
import { BlockPublisherForm } from "../serialise";
import { PublishBlockMissingSteps } from "./PublishBlockMissingSteps";

const Content = styled.div`
  border: ${({ theme }) => `1px solid ${theme.border}`};
  padding: ${tabContentPadding};
`;

const Publish = styled.div`
  grid-column: 2;
`;

const PublishWarning = styled(WarningLine)`
  grid-column: 2;
  margin: 16px 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-wrap: anywhere;
`;
const ErrorOnPublish = styled(ErrorLine)`
  height: auto;
  box-sizing: border-box;
  width: 40%;
  margin-top: 20px;
`;

export interface PublishTabProps extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
}

function usePublishTabProps() {
  return useOutletContext<PublishTabProps>();
}

export function PublishTab() {
  const orgCtx = useOrgCtx();
  const { allowed: canCreateBlockChart } = useCanCreateBlockChart(orgCtx);

  const { publishState, setPublishState, ...formikProps } = usePublishTabProps();
  const { errors, isValid, isSubmitting, values } = formikProps;

  useEffect(() => {
    return () => setPublishState(prev => ({ ...prev, success: false, message: null }));
  }, [setPublishState]);

  const submittedWithError = publishState.submitted && !publishState.success;
  const submittedSuccessfully = publishState.submitted && publishState.success;

  const disabledButton = !canCreateBlockChart || !isValid || isSubmitting;

  return (
    <Content>
      <Form>
        <Fieldset>
          <Publish>
            <div>
              <p>Publish block to registry</p>
              <PrimaryButton type="submit" disabled={disabledButton}>
                {isSubmitting ? "Publishing" : "Publish"}
              </PrimaryButton>
            </div>
            {values.overrides === "" && !publishState.submitted && (
              <PublishWarning>
                Block overrides is empty. This means that no values can be configured at deploy time
              </PublishWarning>
            )}
            {submittedSuccessfully && <SuccessLine>{publishState.message}</SuccessLine>}
            <PublishBlockMissingSteps errors={errors} />
          </Publish>
        </Fieldset>
      </Form>
      {submittedWithError && (
        <ErrorContainer>
          <ErrorOnPublish>{publishState.message}</ErrorOnPublish>
        </ErrorContainer>
      )}
    </Content>
  );
}
