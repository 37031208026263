// redirectOrg returns the new path that we should navigate to when switching networks
// it strips any suffix after /mobile-networks/ so that we aren't left with an invalid combination of
// selected Org and Network
export function redirectOrg(pathname: string, org: string) {
  const match = pathname.match(/^\/app\/[^/]+\/(?<dashboard>[^/]+)(?<page>\/[^/]+)?(?<path>\/.*)?/);

  if (match && match.groups) {
    switch (match.groups.dashboard) {
      case "mobile-networks":
        return `/app/${org}/${match.groups.dashboard}`;
      case "services":
      default:
        const { dashboard, path, page } = match.groups;

        if (!path && !page) return `/app/${org}/${dashboard}`;

        // check if site in url and strip it if necessary
        const infraTab = extractInfraTab(path);
        if (infraTab) return `/app/${org}/${dashboard}${page}${infraTab}`;

        return `/app/${org}/${dashboard}${page}${path || ""}`;
    }
  }
}

function extractInfraTab(path = "") {
  const match = path.match(/^\/site\/[^/]+(?<tab>\/[^/]+)(\/.*)?/);
  if (match && match.groups) return match.groups.tab;
}
