import { useQuery } from "@apollo/client";
import {
  TemplateChartInformationQuery,
  TemplateChartInformationQueryVariables
} from "@src/Generated/graphql";

import TemplateChartInformation from "./TemplateChartInformation.graphql";

export function useFetchTemplateInformation(name: string, version: string) {
  const query = useQuery<TemplateChartInformationQuery, TemplateChartInformationQueryVariables>(
    TemplateChartInformation,
    {
      variables: { name, version },
      skip: !name
    }
  );

  return {
    ...query,
    chart: query.data?.templateChart
  };
}
