import { useEffect, useReducer } from "react";

import { ApolloError, useQuery } from "@apollo/client";
import {
  BlockChart,
  BlockChartType,
  PopulateMarketplaceQuery,
  PopulateMarketplaceQueryVariables,
  TemplateChart
} from "@src/Generated/graphql";
import {
  initReducer,
  MarketplaceAction,
  marketplaceReducer,
  MarketplaceState,
  MarketplaceStateKey,
  sortEnum,
  viewEnum
} from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";
import PopulateMarketplace from "@src/SharedViews/ServiceDesigner/Marketplace/PopulateMarketplace.graphql";

import { useLocalStorageKey } from "./localStorageKey";
import { blockFilter, noVendorTag, serialiseVendors } from "./marketplaceAux";

type ListOptions = {
  [key: string]: viewEnum | sortEnum;
};

export const viewOptions: ListOptions = {
  Grid: viewEnum.grid,
  List: viewEnum.list
};

export const sortOptions: ListOptions = {
  AZ: sortEnum.AZ,
  ZA: sortEnum.ZA
};

export interface MarketplaceProps<T> extends Omit<MarketplaceState, "localStorageKey"> {
  availableCategories: string[];
  otherCategories: string[];
  availableVendors: string[];
  otherVendors: string[];
  allBlocksNumber: number;
  filteredBlocks: T[];
  loading: boolean;
  error: ApolloError;
  dispatch: React.Dispatch<MarketplaceAction>;
  repoUrl: string;
  refetch: () => void;
}

type Chart = Omit<TemplateChart, "__typename"> & Omit<BlockChart, "__typename">;
export interface MarketplaceParams<T extends Chart> {
  repoUrl: string;
  refetch: () => void;
  charts: T[];
  loading: boolean;
  error: ApolloError;
  localStorageKey: string;
}

export function useBlocks(chartType?: BlockChartType) {
  const { loading, error, data, refetch } = useQuery<
    PopulateMarketplaceQuery,
    PopulateMarketplaceQueryVariables
  >(PopulateMarketplace, {
    variables: { category: null, vendors: [], chartType },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true
  });

  const charts = data?.blockCharts ?? [];
  const repoUrl = data?.settings?.block?.url;
  const localStorageKey = useLocalStorageKey(MarketplaceStateKey);

  return {
    loading,
    error,
    refetch,
    charts,
    repoUrl,
    localStorageKey
  };
}

export function useMarketplace<T extends Chart>({
  loading,
  error,
  refetch,
  charts,
  repoUrl,
  localStorageKey
}: MarketplaceParams<T>): MarketplaceProps<T> {
  const [state, dispatch] = useReducer(marketplaceReducer, localStorageKey, initReducer);

  useEffect(() => {
    dispatch({ type: "updateLSKey", value: localStorageKey });
  }, [dispatch, localStorageKey]);

  const { view, sort, ...filters } = state;

  const allCategories = Array.from(
    charts.reduce((prev, c) => new Set([...prev, ...(c.categories || [])]), new Set<string>())
  ).sort();

  const allVendors = serialiseVendors(charts);

  const filteredBlocks = charts
    .filter(b => b && blockFilter(b, filters))
    .sort(sortByDisplayName(sort));

  const [availableCategories, otherCategories] = allCategories.reduce(
    (acc, c) => {
      if (filteredBlocks.some(b => (b?.categories || []).includes(c))) acc[0].push(c);
      else acc[1].push(c);
      return acc;
    },
    [[], []] as [string[], string[]]
  );

  const [availableVendors, otherVendors] = allVendors.reduce(
    (acc, v) => {
      const isAvailable = filteredBlocks.some(b =>
        b?.vendor ? b?.vendor === v : v === noVendorTag
      );
      if (isAvailable) acc[0].push(v);
      else acc[1].push(v);
      return acc;
    },
    [[], []] as [string[], string[]]
  );

  return {
    availableCategories,
    otherCategories,
    availableVendors,
    otherVendors,
    allBlocksNumber: charts.length,
    filteredBlocks,
    ...filters,
    sort,
    view,
    dispatch,
    repoUrl,
    loading,
    error,
    refetch
  };
}

interface ChartName {
  displayName?: string;
}

const sortByDisplayName = (sort: number) => (b1: ChartName, b2: ChartName) => {
  if (sort === sortEnum.AZ) {
    return b1.displayName?.toLowerCase() < b2.displayName?.toLowerCase() ? -1 : 1;
  } else return b1.displayName?.toLowerCase() > b2.displayName?.toLowerCase() ? -1 : 1;
};
