import { FilterEntity } from "@src/Components/Filters/filterEntity";
import { BlockChart, TemplateChart } from "@src/Generated/graphql";
import { MarketplaceState } from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";

export type BlockFilters = Omit<MarketplaceState, "localStorageKey" | "view" | "sort">;

export const noVendorTag = "no vendor";

export function blockFilter(block: BlockChart | TemplateChart, filters: BlockFilters) {
  const { inputFilter, filters: appliedFilters, includeAllTexts, includeAllCategories } = filters;

  if (appliedFilters.length === 0 && !inputFilter) return true;

  const normalizedBlock = { ...block, vendor: block.vendor || noVendorTag };
  const { displayName, categories, vendor, description } = normalizedBlock;
  const allFields = [displayName, description, ...categories, vendor];

  const { textFilters, categoryFilters, vendorFilters } = serialiseFilters(appliedFilters);

  const textCheck = applyFilter(allFields, textFilters, includeAllTexts);
  const currentInputTextCheck = applyFilter(
    allFields,
    splitSearchString(inputFilter),
    includeAllTexts
  );
  const categoriesCheck = applyFilter(categories, categoryFilters, includeAllCategories, true);
  const vendorsCheck = applyFilter([vendor], vendorFilters, false, true);

  return textCheck && categoriesCheck && vendorsCheck && currentInputTextCheck;
}

export function applyFilter(
  blockFields: string[],
  filters: string[],
  includeAll: boolean,
  matchExactly = false
) {
  if (filters.length === 0) return true;

  if (includeAll) {
    return filters.every(f =>
      matchExactly
        ? blockFields.some(field => field.toLowerCase() === f.toLowerCase())
        : blockFields.some(field => field.toLowerCase().includes(f.toLowerCase()))
    );
  } else {
    return filters.some(f =>
      matchExactly
        ? blockFields.some(field => field.toLowerCase() === f.toLowerCase())
        : blockFields.some(field => field.toLowerCase().includes(f.toLowerCase()))
    );
  }
}

export function splitSearchString(value: string) {
  const quoteMatches = [...value.matchAll(/"([^"]*)"/g)];
  let nonQuoteString = value;
  quoteMatches.forEach(([match]) => {
    nonQuoteString = nonQuoteString.replace(match, "");
  });

  const filtersToApply = [...nonQuoteString.split(" "), ...quoteMatches.map(match => match[1])];
  return filtersToApply;
}

export function searchTextPreview(value: string) {
  const texts = splitSearchString(value);

  const textsString = texts.reduce((acc, text) => {
    if (text.trim().length === 0) return acc;
    if (acc.length === 0) return `'${text}'`;
    else return acc + ` - '${text}'`;
  }, "");

  return `${textsString}`;
}

function serialiseFilters<T extends string, V extends string>(filters: FilterEntity<T, V>[]) {
  const textFilters: string[] = [];
  const categoryFilters: string[] = [];
  const vendorFilters: string[] = [];

  filters.forEach(({ type, value }) => {
    if (type === "category") categoryFilters.push(value);
    else if (type === "vendor") vendorFilters.push(value);
    else textFilters.push(value);
  });

  return { textFilters, categoryFilters, vendorFilters };
}

export function serialiseVendors<T extends TemplateChart | BlockChart>(charts: T[]) {
  const vendorsSet = [...new Set(charts.map(b => b?.vendor))];
  return vendorsSet
    .sort()
    .filter(v => v)
    .concat(vendorsSet.some(v => !v) ? noVendorTag : []);
}
