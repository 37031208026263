import "react-loading-skeleton/dist/skeleton.css";

import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { NotFoundPage } from "@src/Components/Navigation/NotFoundPage";
import { ProtectedDashboard } from "@src/Components/Navigation/ProtectedRoute";

import { Licenses } from "./About/Licenses";
import { LicensesView } from "./About/LicensesView";
import { LicensesWeb } from "./About/LicensesWeb";
import { Error } from "./Auth/Error";
import { Login } from "./Auth/Login";
import { Recover } from "./Auth/Recover";
import { DefaultPageRedirect } from "./DefaultPageRedirect";
import { AppErrorFallBack, appFallbackMsg } from "./Error/AppErrorFallback";
import { ErrorReviewPage } from "./Error/ErrorPage";
import { NoDashboard } from "./Error/NoDashboard";
import { NoOrg } from "./Error/NoOrg";
import { useCloudManagerDashboard } from "./Hooks/Permissions/cloudManagerDashboard";
import { useCanAccessComponentVersionsPage } from "./Hooks/Permissions/componentVersions";
import { useCanAccessGlobalSettingsDashboard } from "./Hooks/Permissions/globalSettingsDashboard";
import { useInfrastructureDashboard } from "./Hooks/Permissions/infrastructureDashboard";
import { useLandingPage } from "./Hooks/Permissions/landingPage";
import { useMobileNetworksDashboard } from "./Hooks/Permissions/mobileNetworksDashboard";
import { useServiceOrchestration } from "./Hooks/Permissions/serviceOrchestration";
import { useSoftwarePublisher } from "./Hooks/Permissions/softwarePublisher";
import { useCanAccessTenantSettingsDashboard } from "./Hooks/Permissions/tenantSettingsDashboard";
import { InitialRedirect } from "./InitialRedirect";
import { Layout } from "./Layout";
import { InitialNetworkRedirect } from "./MPNDashboard/MPNRedirects";
import { EditNetworkForm } from "./MPNDashboard/Network/EditNetworkForm";
import { NewNetworkForm } from "./MPNDashboard/Network/NewNetworkForm";
import { OrgCtxValidation } from "./OrgCtxValidation";
import { CloudManagerRedirect } from "./Redirect/CloudManagerRedirect";
import { MobileNetworksRedirect } from "./Redirect/MobileNetworksRedirect";
import { ServiceOrchestrationRedirect } from "./Redirect/ServiceOrchestrationRedirect";
import { SettingsRedirect } from "./Redirect/SettingsRedirect";
import { SoftwarePublisherRedirect } from "./Redirect/SoftwarePublisherRedirect";
import { SoftwarePublisherRoutes } from "./SoftwarePublisher/SoftwarePublisherRoutes";

const AboutPage = lazy(() => import("./About/AboutPage"));
const UserSettings = lazy(() => import("./UserSettings/UserSettings"));
const LandingPage = lazy(() => import("./Landing/LandingPage"));
const AddNetworkPage = lazy(() => import("./MPNDashboard/Network/AddNetworkPage"));
const GlobalSettingsRoutes = lazy(() => import("./AdminDashboard/GlobalSettingsRoutes"));
const TenantSettingsRoutes = lazy(() => import("./AdminDashboard/TenantSettingsRoutes"));
const InfrastructureRoutes = lazy(() => import("./InfrastructureDashboard/InfrastructureRoutes"));
const OrgLessAdminRoutes = lazy(() => import("./AdminDashboard/OrgLessAdminRoutes"));
const Auth = lazy(() => import("./Auth/Auth"));
const ServicesDashboardRoutes = lazy(
  () => import("@src/ServicesDashboard/ServicesDashboardRoutes")
);
const MPNRoutes = lazy(() => import("./MPNDashboard/MPNRoutes"));
const MECRoutes = lazy(() => import("./MECDashboard/MECRoutes"));

export function App() {
  const { allowed: showLanding } = useLandingPage();
  return (
    <>
      <Routes>
        <Route index element={<InitialRedirect />} />

        <Route
          path="app"
          element={
            <ErrorBoundary
              fallback={
                <AppErrorFallBack
                  title="Something went wrong while setting the Dashboard Layout."
                  msg={appFallbackMsg}
                />
              }
            >
              <Layout />
            </ErrorBoundary>
          }
        >
          <Route path="no-org" element={<NoOrg />} />

          <Route path="global-settings/*" element={<OrgLessAdminRoutes />} />

          <Route path=":orgCtx" element={<OrgCtxValidation />}>
            <Route index element={<DefaultPageRedirect />} />

            <Route path="services/*" element={<ServiceOrchestrationRedirect />} />
            <Route path="services/publisher/*" element={<SoftwarePublisherRedirect />} />
            <Route path="admin/*" element={<SettingsRedirect />} />
            <Route path="mec/*" element={<CloudManagerRedirect />} />
            <Route path="mpn/*" element={<MobileNetworksRedirect />} />

            <Route path="no-dashboard" element={<NoDashboard />} />

            {showLanding !== false && <Route path="home" element={<LandingPage />} />}

            <Route
              path="service-orchestration/*"
              element={
                <ProtectedDashboard permissionCheck={useServiceOrchestration()}>
                  <ServicesDashboardRoutes />
                </ProtectedDashboard>
              }
            />

            <Route
              path="software-publisher/*"
              element={
                <ProtectedDashboard permissionCheck={useSoftwarePublisher()}>
                  <SoftwarePublisherRoutes />
                </ProtectedDashboard>
              }
            />

            <Route
              path="mobile-networks"
              element={
                <ProtectedDashboard permissionCheck={useMobileNetworksDashboard()}>
                  <Outlet />
                </ProtectedDashboard>
              }
            >
              <Route index element={<InitialNetworkRedirect />} />
              <Route path=":networkCtx/*" element={<MPNRoutes />} />
              <Route path="network" element={<AddNetworkPage />}>
                <Route index element={<Navigate to="add" replace />} />
                <Route path="add" element={<NewNetworkForm />} />
                <Route path=":networkCtx/edit" element={<EditNetworkForm />} />
              </Route>
            </Route>

            <Route
              path="cloud-manager/*"
              element={
                <ProtectedDashboard permissionCheck={useCloudManagerDashboard()}>
                  <MECRoutes />
                </ProtectedDashboard>
              }
            />

            <Route
              path="settings/*"
              element={
                <ProtectedDashboard permissionCheck={useCanAccessTenantSettingsDashboard()}>
                  <TenantSettingsRoutes />
                </ProtectedDashboard>
              }
            />
            <Route
              path="infrastructure/*"
              element={
                <ProtectedDashboard permissionCheck={useInfrastructureDashboard()}>
                  <InfrastructureRoutes />
                </ProtectedDashboard>
              }
            />

            <Route
              path="settings/*"
              element={
                <ProtectedDashboard permissionCheck={useCanAccessTenantSettingsDashboard()}>
                  <TenantSettingsRoutes />
                </ProtectedDashboard>
              }
            />

            <Route
              path="global-settings/*"
              element={
                <ProtectedDashboard permissionCheck={useCanAccessGlobalSettingsDashboard()}>
                  <GlobalSettingsRoutes />
                </ProtectedDashboard>
              }
            />

            <Route path="about">
              <Route
                index
                element={
                  <ProtectedDashboard permissionCheck={useCanAccessComponentVersionsPage()}>
                    <AboutPage />
                  </ProtectedDashboard>
                }
              />
              <Route path="licenses" element={<LicensesView />}>
                <Route path="web" element={<LicensesWeb />} />
                <Route path=":component" element={<Licenses />} />
              </Route>
            </Route>

            <Route path="user/settings" element={<UserSettings />} />
            <Route path="error-review" element={<ErrorReviewPage />} />
          </Route>
        </Route>

        <Route
          path="auth"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recover />} />
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </>
  );
}
