import { FormikProps } from "formik";
import { useCallback, useEffect, useRef } from "react";
import { NavigateFunction, Outlet, useOutletContext } from "react-router-dom";

import { ChartSkeleton } from "@src/Components/ChartSelector/Chart";
import { ChartFieldFilters } from "@src/Components/ChartSelector/ChartFieldFilters";
import { ChartsDisplay } from "@src/Components/ChartSelector/ChartsDisplay";
import {
  ChartsContent,
  NoChartsMessage,
  Wrapper
} from "@src/Components/ChartSelector/ChartSelectorList";
import { TemplateChart } from "@src/Generated/graphql";
import { useMarketplace } from "@src/Hooks/marketplace";

import { BlockPublisherForm } from "../serialise";
import { TemplateBlock } from "./TemplateBlock";
import { useTemplates } from "./templates";

interface PublisherProps extends FormikProps<BlockPublisherForm> {
  navigate: NavigateFunction;
}

function usePublisherProps() {
  return useOutletContext<PublisherProps>();
}

export function Templates() {
  const { navigate, ...formikProps } = usePublisherProps();

  const marketplaceProps = useMarketplace(useTemplates());
  const { filteredBlocks: filteredTemplates, view, loading, repoUrl } = marketplaceProps;

  const openTemplateInformation = useCallback(
    (templateName: string, version: string) => {
      navigate(`templates/${templateName}?v=${version}`);
    },
    [navigate]
  );

  const { setValues, values } = formikProps;

  const hasAddedTemplate = useRef(false);
  const addVersionTemplate = useCallback(
    (chart: TemplateChart) => {
      const template: BlockPublisherForm = {
        templateName: chart.name,
        displayName: chart.displayName,
        selectedVersion: chart.version,
        values: chart.valuesYaml || "",
        overrides: chart.overridesYaml || "",
        descriptor: {
          name: "",
          version: "",
          displayName: chart.displayName,
          vendor: chart.vendor,
          description: chart.description,
          iconUrl: chart.logoUrl,
          categories: chart.categories
        }
      };
      setValues(template);
      hasAddedTemplate.current = true;
    },
    [setValues]
  );
  useEffect(() => {
    if (hasAddedTemplate.current) {
      navigate("editor");
    }
  }, [navigate, values]);

  const context = { addVersionTemplate };

  return (
    <>
      <Wrapper>
        <ChartFieldFilters {...marketplaceProps} />

        <div>
          <ChartsDisplay {...marketplaceProps} chartType="template" />
          <ChartsContent $view={view}>
            {!loading ? (
              <>
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map(b => (
                    <TemplateBlock
                      openTemplateInformation={openTemplateInformation}
                      key={b.id}
                      view={view}
                      template={b}
                      addVersionTemplate={addVersionTemplate}
                      {...formikProps}
                    />
                  ))
                ) : (
                  <NoChartsMessage>
                    {repoUrl === ""
                      ? "Template repo URL must be set via the Customizations tab before using the Marketplace."
                      : "There are no templates with the applied filters."}
                  </NoChartsMessage>
                )}
              </>
            ) : (
              <>
                <ChartSkeleton elements={18} />
              </>
            )}
          </ChartsContent>
        </div>
      </Wrapper>
      <Outlet context={context} />
    </>
  );
}
