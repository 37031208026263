import { AxiosResponse } from "axios";
import { UseQueryOptions } from "react-query";

import { CheckPermissionResult } from "@ory/client";
import { permissions } from "@src/Auth/kratos";
import { useReactQuery } from "@src/Clients/ReactQuery/reactQueryClient";
import { useSession } from "@src/Session";

export type CheckPermsQueryOptions = UseQueryOptions<AxiosResponse<CheckPermissionResult>>;
export interface PermissionArgs {
  relation: string;
  objectId?: string;
  objectType: string;
}
export interface PermCheckResponse {
  allowed: boolean | null;
  loading: boolean;
}

export function usePermissionCheck(
  { relation, objectId = "", objectType }: PermissionArgs,
  queryOptions: CheckPermsQueryOptions = {}
) {
  const { session } = useSession();
  const userId = session?.identity?.id || "";

  const enabled = !!userId && !!objectId && !!relation && !!objectType;

  const { data, isLoading: loading } = useReactQuery(
    [objectType, objectId || "", relation, userId],
    () =>
      permissions.checkPermission({
        namespace: objectType,
        object: objectId,
        relation: relation,
        subjectSetNamespace: "User",
        subjectSetObject: userId,
        subjectSetRelation: ""
      }),
    {
      enabled,
      ...queryOptions
    }
  );

  const allowed = data?.data?.allowed ?? null;

  return { allowed, loading };
}
