type LocalStorageState = {
  localStorageValue?: string;
  key: string;
};
export const initialState: LocalStorageState = {
  localStorageValue: null,
  key: ""
};

export type LocalStorageAction = { type: "change"; value: string };

export function localStorageReducer(
  state: LocalStorageState,
  action: LocalStorageAction
): LocalStorageState {
  switch (action.type) {
    case "change":
      window.localStorage.setItem(state.key, action.value);
      return {
        ...state,
        localStorageValue: action.value
      };
    default:
      return state;
  }
}
