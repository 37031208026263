import { FormikProps } from "formik";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Title } from "@src/Components/ChartSelector/ChartSelectorList";
import {
  ConfigWrapper,
  GridContainer,
  ParameterButton,
  Sidebar,
  ValueStatusForm
} from "@src/Components/ChartSelector/ConfigStyles";
import { EditorStatus } from "@src/Components/EmbeddedEditor/editorStatus";
import {
  EmbeddedEditor,
  EmbeddedEditorSkeleton
} from "@src/Components/EmbeddedEditor/EmbeddedEditor";
import {
  EditedChart,
  PublisherEditorAction
} from "@src/Components/EmbeddedEditor/Publisher/publisherReducerTypes";
import { H2 } from "@src/Components/Text";
import { ChartMetaInput, TemplateChart } from "@src/Generated/graphql";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { softwarePublisher } from "@src/Hooks/Dashboard/dashboards";
import { useEditorTheme } from "@src/Hooks/editorTheme";
import { PublisherEditingSection } from "@src/SharedViews/ServiceDesigner/Designer/editingSections";
import { EditorSectionButton } from "@src/SharedViews/ServiceDesigner/Designer/EditorSectionButton";

import { PublisherEditorState } from "../editorPublisherReducer";
import { BlockPublisherForm } from "../serialise";
import { ChartYamlForm } from "./ChartYamlForm";

interface BlockConfigPanelProps extends FormikProps<BlockPublisherForm> {
  editorState: PublisherEditorState;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
  originalTemplate: Omit<TemplateChart, "name" | "version">;
  loading: boolean;
}

export function BlockConfigPanel({
  editorState,
  editorDispatch,
  originalTemplate,
  loading,
  ...formikProps
}: BlockConfigPanelProps) {
  const { values: newBlock } = formikProps;
  const { templateName: name } = newBlock;
  const orgCtx = useOrgCtx();

  useEffect(() => {
    editorDispatch({
      type: "init",
      payload: {
        overrides: originalTemplate?.overridesYaml || "",
        values: originalTemplate?.valuesYaml || ""
      }
    });
  }, [editorDispatch, originalTemplate?.overridesYaml, originalTemplate?.valuesYaml]);

  return (
    <ConfigWrapper>
      {name.length ? (
        <ConfigureBlock
          editorState={editorState}
          editorDispatch={editorDispatch}
          loading={loading}
          {...formikProps}
        />
      ) : (
        <>
          <H2>No template has been selected</H2>
          <p>
            Try selecting a template from the{" "}
            <NavLink to={`/${softwarePublisher.route(orgCtx)}/publisher/templates`}>
              publisher
            </NavLink>
          </p>
        </>
      )}
    </ConfigWrapper>
  );
}

interface ConfigureBlockProps extends FormikProps<BlockPublisherForm> {
  editorState: PublisherEditorState;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
  loading: boolean;
}

function ConfigureBlock({
  editorState,
  editorDispatch,
  loading,
  ...formikProps
}: ConfigureBlockProps) {
  const { setFieldValue, values: newBlock, errors } = formikProps;

  const { displayName } = newBlock;

  const [editingSection, setEditingSection] = useState<PublisherEditingSection>(
    PublisherEditingSection.Overrides
  );

  const { theme, changeTheme } = useEditorTheme();

  const editBlockValue = useCallback(
    (input: string | ChartMetaInput, field: EditedChart | "descriptor") => {
      setFieldValue(field, input);
    },
    [setFieldValue]
  );

  const isDescriptorValid = !errors.descriptor;

  const changeTab = useCallback(
    (section: PublisherEditingSection) => {
      if (section !== PublisherEditingSection.Values) {
        editorDispatch({
          type: "save",
          payload: { chart: EditedChart.Values, block: displayName }
        });
      }
      if (section !== PublisherEditingSection.Overrides) {
        editorDispatch({
          type: "save",
          payload: { chart: EditedChart.Overrides, block: displayName }
        });
      }
      setEditingSection(section);
    },
    [editorDispatch, setEditingSection, displayName]
  );
  return (
    <>
      <H2>
        Configure <strong>{displayName}</strong>
      </H2>
      <GridContainer>
        <Sidebar>
          <Title>block fields</Title>
          <EditorSectionButton
            section={editingSection}
            sectionName={PublisherEditingSection.Values}
            setSection={changeTab}
            editorState={!loading ? editorState.values.status : EditorStatus.Default}
            titleLabel="values"
          />
          <EditorSectionButton
            section={editingSection}
            sectionName={PublisherEditingSection.Overrides}
            setSection={changeTab}
            editorState={!loading ? editorState.overrides.status : EditorStatus.Default}
            titleLabel="overrides"
          />
          <ParameterButton
            onClick={() => changeTab(PublisherEditingSection.Descriptor)}
            $selected={editingSection === PublisherEditingSection.Descriptor}
          >
            {PublisherEditingSection.Descriptor}
            <ValueStatusForm
              $invalid={!isDescriptorValid}
              title={(isDescriptorValid ? "valid" : "invalid") + " descriptor"}
            >
              {isDescriptorValid ? "✓" : "!"}
            </ValueStatusForm>
          </ParameterButton>
        </Sidebar>

        <>
          {!loading ? (
            <>
              {editingSection === PublisherEditingSection.Descriptor && (
                <ChartYamlForm {...formikProps} />
              )}
              {editingSection === PublisherEditingSection.Values && (
                <EmbeddedEditor
                  key={`${newBlock.displayName}-values`}
                  blockDisplayName={newBlock.displayName}
                  defaultYaml={editorState.values.original}
                  theme={theme}
                  configurationParameter={editingSection}
                  isEditing={false}
                  changeTheme={changeTheme}
                  editBlockValue={editBlockValue}
                  editedChart={EditedChart.Values}
                  editorValues={newBlock.values}
                  editorDispatch={editorDispatch}
                  savedValue={editorState.values.saved}
                />
              )}
              {editingSection === PublisherEditingSection.Overrides && (
                <EmbeddedEditor
                  key={`${newBlock.displayName}-overrides`}
                  blockDisplayName={newBlock.displayName}
                  defaultYaml={editorState.overrides.original}
                  theme={theme}
                  configurationParameter={editingSection}
                  isEditing={false}
                  changeTheme={changeTheme}
                  editBlockValue={editBlockValue}
                  editedChart={EditedChart.Overrides}
                  editorValues={newBlock.overrides}
                  editorDispatch={editorDispatch}
                  savedValue={editorState.overrides.saved}
                />
              )}
            </>
          ) : (
            <EmbeddedEditorSkeleton />
          )}
        </>
      </GridContainer>
    </>
  );
}
