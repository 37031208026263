import PublisherIcon from "@img/cloud-arrow-up-solid.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { softwarePublisher } from "@src/Hooks/Dashboard/dashboards";

export function SoftwarePublisherNavbar() {
  const orgCtx = useOrgCtx();

  const rootPath = softwarePublisher.route(orgCtx);
  return (
    <>
      <NavIcons>
        <NavItem linkTo={rootPath + "/publisher"} label="Publisher" icon={PublisherIcon} />
      </NavIcons>
    </>
  );
}
