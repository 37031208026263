import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { cloudManager, mobileNetworksDashboard } from "@src/Hooks/Dashboard/dashboards";
import { useCloudManagerDashboard } from "@src/Hooks/Permissions/cloudManagerDashboard";
import { useMobileNetworksDashboard } from "@src/Hooks/Permissions/mobileNetworksDashboard";
import { useCanReadSim } from "@src/Hooks/Permissions/sim";

interface LandingPermissionsProps {
  networkId: string;
  coreId: string;
}

export function useLandingPermissions({
  networkId,
  coreId
}: LandingPermissionsProps): LandingPermissionsResult {
  const orgCtx = useOrgCtx();

  const { allowed: canReadSIM } = useCanReadSim(coreId);
  const { allowed: allowedMobileNetworks } = useMobileNetworksDashboard();
  const { allowed: allowedCloudManager } = useCloudManagerDashboard();

  return {
    appManager: {
      allowed: allowedCloudManager,
      url: appManagerUrl(orgCtx)
    },
    infrastructureManager: {
      allowed: allowedCloudManager,
      url: infraManagerUrl(orgCtx)
    },
    mpnManager: {
      allowed: allowedMobileNetworks,
      url: mpnManagerUrl(networkId, orgCtx)
    },
    simCardsTable: {
      allowed: canReadSIM && allowedMobileNetworks,
      url: simCardsTableUrl(networkId, coreId, orgCtx)
    }
  };
}

export function appManagerUrl(org?: string) {
  return `${cloudManager.route(org)}/operate/deployed-apps`;
}
export function infraManagerUrl(org?: string) {
  return `${cloudManager.route(org)}/mec-sites/inventory`;
}
export function mpnManagerUrl(network: string, org?: string) {
  return `${mobileNetworksDashboard.route(org, network)}/operate/overview`;
}
export function simCardsTableUrl(network: string, core: string, org?: string) {
  return `${mobileNetworksDashboard.route(org, network)}/sim-cards/${core}/manage`;
}

export interface LandingPermission {
  allowed: boolean | null;
  url: string;
}

interface LandingPermissionsResult {
  appManager: LandingPermission;
  infrastructureManager: LandingPermission;
  mpnManager: LandingPermission;
  simCardsTable: LandingPermission;
}
