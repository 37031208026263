import ServiceDesignerIcon from "@img/project-diagram-solid.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { serviceOrchestration } from "@src/Hooks/Dashboard/dashboards";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";

export function ServicesNavbar() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const rootPath = serviceOrchestration.route(orgCtx || defaultOrg);
  return (
    <>
      <NavIcons>
        <NavItem linkTo={rootPath + "/overview"} label="Services" icon={ServicesIcon} />
        <NavItem
          linkTo={rootPath + "/service-designer"}
          label="Designer"
          icon={ServiceDesignerIcon}
        />
      </NavIcons>
    </>
  );
}
